import React from "react";
import styled from "styled-components";
import media from "../../theme/media";

const StyledMenuSection = styled.div`
  position: relative;
  margin-top: 0.625rem;
  min-height: 1.25rem;
  border: 1px solid transparent;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceDivider};
`;

const StyledLabel = styled.div`
  position: absolute;
  top: -0.625rem;
  left: 0.5rem;
  display: flex;
  margin-inline: -0.125rem;
  padding: 0 0.375rem 0 0.375rem;
  align-items: center;
  gap: 0.125rem;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.text};
  background-color: ${(p) => p.theme.colors.neutrals.background};
`;

const StyledPanel = styled.div`
  padding: 1rem;

  &[data-has-label="true"] {
    padding: 1.25rem 1rem 1rem 1rem;
  }

  @media ${media.lg} {
    padding: 0.75rem 0.75rem;

    &[data-has-label="true"] {
      padding: 1.125rem 0.75rem 0.75rem 0.75rem;
    }
  }
`;

interface MenuSectionProps {
  children?: React.ReactNode;
  label?: React.ReactNode;
}

const MenuSection = ({ children, label }: MenuSectionProps) => {
  return (
    <StyledMenuSection>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledPanel data-has-label={!!label}>{children}</StyledPanel>
    </StyledMenuSection>
  );
};

export default MenuSection;
